import React, { useState, useRef } from "react";
import ChevronRight from "../../img/arrow-square-right-duotone.svg";
import emailjs from "emailjs-com";

import email from '../../img/mail.png'
import address from '../../img/address.png'
import phone from '../../img/phone.png'

const initialForm = {
  name: "",
  email:"",
  msj: "",
  subject:""
};

const Section6 = ({ data }) => {
  let { contact, msjSend } = data;

  const frm = useRef();

  const [form, setForm] = useState(initialForm);
  const [sending, setSending] = useState(null);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let regexName = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
    let regexComments = /^.{1,255}$/;

    if (!form.name && !form.msj) {
      setError(msjSend.dataEmpty);
      setTimeout(() => {
        setError(null);
      }, 3000);
      return;
    } else {
      if (!regexName.test(form.name.trim())) {
        setError(msjSend.msjName);
        setTimeout(() => {
          setError(null);
        }, 3000);
        return;
      }
      if (!form.msj) {
        setError(msjSend.msjText);
        setTimeout(() => {
          setError(null);
        }, 3000);
        return;
      }

      setSending(msjSend.process);
      emailjs
        .sendForm(
          "service_yzjbmcr",
          "template_t8kqrdn",
          frm.current,
          "39ttKRjXKjHyan0sJ"
        )
        .then(
          (result) => {
            setSending(msjSend.successText);
            setForm(initialForm);
            setTimeout(() => {
              setSending(null);
            }, 3000);
          },
          (error) => {
            setSending(null);
            setError(error.text);
            setTimeout(() => {
              setError(null);
            }, 3000);
          }
        );
    }
  };

  return (
    <section id="section-6" className="contact-frm grid">
      <div className="wrapper-sec-6">
        <div>
          <h2>Let's Get In Touch</h2>
          <p>Have any Question ?</p>
          {/* <p>{contact.content2}</p> */}
          <form ref={frm} onSubmit={handleSubmit} id="sending">
            <div className="two-column">
              <div>
                <label htmlFor="name">{contact.name}</label>
                <input
                  name="name"
                  id="name"
                  type="text"
                  onChange={handleChange}
                  value={form.name}
                />
              </div>
              <div>
                <label htmlFor="email">Email</label>
                <input
                  name="email"
                  id="email"
                  type="text"
                  onChange={handleChange}
                  value={form.email}
                />
              </div>
            </div>
            <div>
              <label htmlFor="subject">Subject</label>
              <input
                name="subject"
                id="subject"
                type="text"
                onChange={handleChange}
                value={form.subject}
              />
            </div>
            <div>
              <label htmlFor="msj">Message</label>
              <textarea
                name="msj"
                id="msj"
                cols="30"
                rows="20"
                value={form.msj}
                onChange={handleChange}
              ></textarea>
            </div>
            {error && (
              <span style={{ color: "#f94e3d" }} id="msj-alert">
                {error}
              </span>
            )}
            {sending && (
              <span style={{ color: "#3b94f9" }} id="msj-alert">
                {sending}
              </span>
            )}
            <button id="subscribe-button" type="submit">
              <img src={ChevronRight} alt="down" />
              <i className="fas fa-arrow-square-right" aria-hidden="true"></i>
              {contact.send}
            </button>
          </form>
        </div>
        <div className="adresss">
          <ul className="address-item">                  
              <li>
                <img src={address} alt=""/>
                <div className="content">
                  <h6 >Address</h6>
                  <p> <a href="https://goo.gl/maps/hwFswdTwo3sN82yx6" target="_blank" rel="noopener noreferrer">Go Vector One, LLC
                      1620 240 th St
                      Harbor City, CA 90710</a> </p>
                </div>
              </li>            
              <li>
              <img src={email} alt=""/>
                <div className="content">
                    <h6>Email:</h6>
                    <p><a href="mailto:sales@vectorone.us">sales@vectorone.us</a></p>
                </div>
              </li>            
              <li>
                <img src={phone} alt=""/>
                  <div className="content">
                      <h6 >Phone</h6>                      
                      <p><a href="tel:+1 323 458 5051">+1 323 458 5051</a></p>
                  </div>
              </li>
          </ul>
        </div>
      </div>      
    </section>
  );
};

export default Section6;
