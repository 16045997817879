import React from "react";

const Section4 = ({ data }) => {
  let { services } = data;

  return (
    <div id="section-4" className="services-txt grid">
      <div>
        <h3>{services.titleServices}</h3>       
      </div>

      <div>
        <div id="card-1">
          <div>
            <h4>MEDICAL DEVICE ASSEMBLY & PACKAGING</h4>
            <p>Vector One provides full-service contract manufacturing services to the medical device market, including contract assembly and contract packaging in our ISO 13485 registered facility.</p>
           
          </div>
        </div>

        <div id="card-2">
          <div>
            <h4>CLEANROOM PRODUCTION & ASSEMBLY</h4>
            <p>Vector One has over 3,000 square feet of certified Class 10,000 Cleanroom space dedicated to medical device packaging, assembly and kitting. Your products can be cleaned, assembled, packaged and labeled in the same facility, ensuring the highest level of quality.</p>
           
          </div>
        </div>
        
        
        
        <div id="card-3">
          <div>
            <h4>ROBOTIC TUBE & VIAL FILLING</h4>
            <p>Our robotic filling machine are capable of filling 300,000 units per 8hr shift. This can easily be scaled to 1 million units per day if needed.</p>
           
          </div>
        </div>
        <div id="card-4">
          <div>
            <h4>PCB Assembly</h4>
            <p>Our team has decades of experience in PCB assembly, cable harness assembly, final box build, and functional testing for customers all over the world.</p>
           
          </div>
        </div>
        <div id="card-5">
          <div>
            <h4>FAA VALIDATED SOFTWARE</h4>
            <p>VectorOne ‘s in-house software team provides client portals for real-time production and quality metrics.</p>
            
          </div>
        </div>
        <div id="card-6">
          <div>
            <h4>RAPID MANUFACTURING</h4>
            <p>Our team handles all our design in house. This includes Prototype Design/DFM, Automation Design, CNC machining, and 3D printing.</p>            
          </div>
        </div>
        <div id="card-7">
          <div>
            <h4>WORLD CLASS QUALITY ASSURANCE</h4>
            <p>Our quality program is instrumental to our success. We are ISO 13485 & ISO 9001 certified, and CGMP compliant. Our MES, WMS, & EDMS systems are designed for each product we manufacture, giving us traceability of all raw materials and employee training in each step of the manufacturing process.</p>
           
          </div>
        </div>
        <div id="card-8">
          <div>
            <h4>PRODUCT PROCESS DESIGN & VALIDATION</h4>
            <p>VectorOne can take your product and design a manufacturing/assembly process around it. That comes complete with our Process Validation run within the specifications of our ISO 13485 certification.</p>
            
          </div>
        </div>
        <div id="card-9">
          <div>
            <h4>ALL ABOUT QUALITY</h4>
            <div>
              We are:
              <ul>
                <li>ISO 9001 approved for engineering design, development, manufacturing and service</li>
                <li>ISO 13485 approved for medical device manufacturing and service.</li>
              </ul>
            </div>
            
           
          </div>
        </div>

        
      </div>


      



    </div>
  );
};
export default Section4;
