import React, { useEffect } from "react";

const ArrowUp = () => {

  const activeUp = (e) => {
    let scroll = document.querySelector(`.navbar`).offsetTop;    
    window.scrollTo({ top: scroll, left: 0, behavior: "smooth" });

  };

  const ejecuteScroll = (e) => {
    let sec1h = document.querySelector("#section-1").offsetHeight;
    let sec2h = document.querySelector("#section-2").offsetHeight;
    let sec3h = document.querySelector("#section-3").offsetHeight;
    let sec4h = document.querySelector("#section-4").offsetHeight;
    let sec5h = document.querySelector("#section-5").offsetHeight;
    let topNavCircle = window.pageYOffset + window.innerHeight + 200;
    
    if (topNavCircle > sec1h + sec2h + sec3h + sec4h + sec5h) {
      document.querySelector(".arrow-up").style.display = "flex";
    }else{
      document.querySelector(".arrow-up").style.display = "none";
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", ejecuteScroll);
    return () => {
      window.removeEventListener("scroll", ejecuteScroll);
    };
  }, []);

  return (
    <div className="arrow-up" onClick={activeUp}>
      <span></span>
      <span></span>
    </div>
  )
}

export default ArrowUp