import { useState } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";

import "./App.css";
import "./css/main.scss";

import Header from "./components/header";
import dataEn from "./data/data-en.json";
import dataEs from "./data/data-es.json";
import Home from "./components/home";
import ArrowDown from "./components/arrow_down";
import Terms from "./components/terms";
import Privacy from "./components/privacy";
import Footer from "./components/footer";
import Err404 from "./components/sections/err404";
import ArrowUp from "./components/arrowUp";

function App() {
  let idiomNav = navigator.language || navigator.userLanguage;

  if (idiomNav === "es-MX" || idiomNav === "es" || idiomNav === "es-419") {
    idiomNav = true;
  } else {
    idiomNav = false;
  }

  const [idiom, setIdiom] = useState(idiomNav);
  const [bgFooter, setBgFooter] = useState("");

  const getLg = (e) => {
    setIdiom(false)
  };

  const bgA = (bg) => {
    setBgFooter(bg);
  };

  return (
    <>
      <HashRouter>
      <Header data={idiom ? dataEs : dataEn} idiom={idiom} getLg={getLg} bg={bgFooter}/>
        <Switch>
          <Route exact path="/terms/">
            <Terms data={idiom ? dataEs : dataEn} bgA={bgA}/>
          </Route>
          <Route exact path="/privacy/">
            <Privacy data={idiom ? dataEs : dataEn} bgA={bgA}/>
          </Route>
          <Route exact path="/">            
            <Home data={idiom ? dataEs : dataEn} />
            <ArrowDown />
            <ArrowUp/>
          </Route>
          <Route path="*" component={Err404} />
        </Switch>
      </HashRouter>
      <Footer />
    </>
  );
}

export default App;
