import React from "react";

const Err404 = () => {
  return (
    <div className="err-404">
      <h1>ERROR 404</h1>
      <p>not found</p>
    </div>
  );
};

export default Err404;
