import React, { useEffect } from "react";

import ChevronDown from "../../img/chevron-down-solid.svg";

const Section3 = ({ data }) => {

  const writEffect = () => {
    let index = 0;
    let arrCounter = 0;
    let keywords = document.querySelector(".label-typingg").textContent;

    var autotype = function () {
      var element;
      if (index <= keywords.length && arrCounter === 0) {
        element = keywords.substring(0, index++);

        document.querySelector(".label-typingg").textContent = element;
        if (index > keywords.length) {
          arrCounter = 1;
          setTimeout(autotype, 2000);
        } else {
          setTimeout(autotype, 100);
        }
      }
    };
    autotype();
  };

  
  useEffect(() => {
    let counter = 0;
    const flag = (e) => {
      let windowHeight = window.pageYOffset;
      let contenido2 = document.querySelector("#section-3").offsetTop;
      if (windowHeight >= contenido2 - 200) {
        counter++;
        if (counter <= 1) {
          document.querySelector(".typed-cursor-2").classList.remove("v-hidden");
          document.querySelector(".label-typingg").classList.remove("v-hidden");
          writEffect();
        }
      }
    };
    window.addEventListener("scroll", flag);
    return () => {
      window.removeEventListener("scroll", flag);
    };
  }, [])

  const activeDown = (e) => {
    var sectionNext = e.target.dataset.menu;
    let scroll = document.querySelector(`#section-${sectionNext}`).offsetTop;
    if (sectionNext === 2) {
      window.scrollTo({ top: scroll, left: 0, behavior: "smooth" });
    } else {
      window.scrollTo({ top: scroll, left: 0, behavior: "smooth" });
    }
  };

  return (
    <div id="section-3" className="container-about grid">
      <div className="about">
        <div className="line-left">
          <p data-menu="4" onClick={activeDown}>
            <img src={ChevronDown} alt="down" /> VectorOne v2022
          </p>
        </div>

        <h3>Who we are</h3>
        <p>
        VectorOne is an ISO 13485 Certified and FDA Registered cGMP manufacturer specializing in high volume tube filling and kitting. We have shipped over 30,000,000 units since 2020 for our clients. Offering both automated and manual assembly, our agile approach delivers a better 
        <span className="label-typingg v-hidden"> outcome with superior visibility and predictability.</span>
        <span className="typed-cursor-2 v-hidden">|</span>
        </p>

        
      </div>
    </div>
  );
};

export default Section3;
