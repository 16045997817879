import React, { useEffect } from "react";


import ImgCity from "../../img/logo.webp";

const Section1 = () => {

  useEffect(() => {
    let el = document.querySelector(".view-city")   
    el.classList.add("active-zoom");
  }, []);

  return (
    <div id="section-1" className="bg-dark">
      <div className="view-city">
        <div>
          <div className="txt-main">
            <img src={ImgCity} alt="" />
            <h1>
            During the pandemic VectorOne produced 30,000,000 units around the clock and shipped them to 18 US States, USAF, Navy, and Congress. <b>What can VectorOne do for you?</b>
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section1;
