import React from "react";


import c_1 from "../../img/cert1.gif";
import c_2 from "../../img/cert2.jpg";
import c_3 from "../../img/cert3.png";
import c_4 from "../../img/cert4.png";

const Section5 = ({ data }) => {


  return (
    //   {/* <!--  owl carrousel --> */}
    <section id="section-5" className="grid slid-proyects">
      <div id="change-gray">
        <div className="row">
          <div className="header-text">
            <h4 id="center-with-loop">Certifications & Facilities</h4>
            {/* <p>{owl.content}</p> */}            
          </div>  
          <div className="wrapper-blocks">
            <div>
              <p>MADE IN USA</p>
              <img src={c_1} alt=""/>
            </div>
            <div>
              <p>FDA APPROVED</p>
              <img src={c_2} alt=""/>
            </div>
            <div>
              <p>cGMP FACILITY</p>
              <img src={c_3} alt=""/>
            </div>
            <div>
              <p>ISO 13485:2016</p>
              <img src={c_4} alt=""/>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section5;
