import React, { useEffect } from "react";

const Section2 = ({ data }) => {

  const writEffect = () => {
    let index = 0;
    let arrCounter = 0;
    let keywords = document.querySelector(".label-typing").textContent;

    var autotype = function () {
      var element;
      if (index <= keywords.length && arrCounter === 0) {
        element = keywords.substring(0, index++);

        document.querySelector(".label-typing").textContent = element;
        if (index > keywords.length) {
          arrCounter = 1;
          setTimeout(autotype, 2000);
        } else {
          setTimeout(autotype, 100);
        }
      }
    };
    autotype();
  };

  useEffect(() => {
    let counter = 0;
    const flag = (e) => {
      let windowHeight = window.pageYOffset;
      let contenido2 = document.querySelector(".slog-text").offsetTop;
      if (windowHeight - 200 >= contenido2) {
        counter++;
        if (counter <= 1) {
          document.querySelector(".typed-cursor").classList.remove("v-hidden");
          document.querySelector(".label-typing").classList.remove("v-hidden");
          writEffect();
        }
      }
    };
    window.addEventListener("scroll", flag);
    return () => {
      window.removeEventListener("scroll", flag);
    };
  }, []);

  return (
    <div className="slog-text grid">
      <div id="section-2">
        <div className="search__label">
          <p>

            VectorOne is a full-service Medical Device CDMO.
            <br />
            In March of 2020, as a direct response to the COVID-19 pandemic,
            <br />
            VectorOne was formed by a small group of friends
            <br />
            looking to fill the growing need of COVID-19 PCR test kits.
            <br />
            Rapidly scaling to 700 employees in 5 months, VectorOne produced
            <br />
            <span className="label-typing v-hidden"> and shipped over 30 million test kits.</span>
            <span className="typed-cursor v-hidden">|</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Section2;
